<template>
  <div v-if="invoice">
    <header class="my-6 flex justify-between">
      <div class="mx-2">
        <h1 class="font-bold text-2xl lowercase">
          Test<span class="text-secondary">Candidates</span>
        </h1>
        <p class="my-2">
          1 Lindsey Street<br>
          London EC1A 9HP<br>
          United Kingdom
        </p>
        <p class="my-2">
          VAT number: 278478345<br>
          Company number: 10885111
        </p>
      </div>
      <div class="mx-2 text-right">
        <p class="my-2 text-2xl font-bold">
          {{ invoice.organisationName }}
        </p>
        <h1 class="my-2 text-lg">
          Invoice number <strong>{{ invoice.number }}</strong>
        </h1>
        <address
          v-if="invoice.organisationAddress"
          class="not-italic"
          v-html="companyAddress(invoice.organisationAddress)"
        >
        </address>
        <p
          v-if="invoice.organisationVatNumber"
          class="my-2"
        >
          VAT number: {{ invoice.organisationVatNumber }}
        </p>
      </div>
    </header>
    <BaseCard
      class="my-6"
      no-padding
    >
      <div
        class="m-2"
      >
        {{ formatDate(parseISO(invoice.date)) }}
      </div>
      <table class="table-auto w-full">
        <thead>
          <tr>
            <th class="p-2 text-left">
              Item
            </th>
            <th class="p-2 text-left">
              Dates
            </th>
            <th class="p-2 text-left">
              Quantity
            </th>
            <th class="p-2 text-right">
              Unit
            </th>
            <th class="p-2 text-right">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(lineItem, i) in invoice.lineItems"
            :key="i"
          >
            <th class="px-2 py-1 text-left">
              {{ lineItem.description }}
            </th>
            <td class="px-2 py-1">
              {{ formatDate(parseISO(lineItem.startDate), 'd MMM yyyy') }}
              –
              {{ formatDate(parseISO(lineItem.endDate), 'd MMM yyyy') }}
            </td>
            <td class="px-2 py-1">
              {{ lineItem.quantity }}
            </td>
            <td class="px-2 py-1 text-right">
              {{ formatCurrency(lineItem.amount / 100, invoice.currency) }}
            </td>
            <td class="px-2 py-1 text-right">
              {{ formatCurrency(lineItem.amount / 100, invoice.currency) }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th
              colspan="4"
              class="px-2 py-1 text-right"
            >
              Subtotal
            </th>
            <td
              class="px-2 text-right"
            >
              {{ formatCurrency(invoice.subtotal / 100, invoice.currency) }}
            </td>
          </tr>
          <tr>
            <th
              colspan="4"
              class="px-2 py-1 text-right"
            >
              Discount
            </th>
            <td
              class="px-2 text-right"
            >
              {{ formatCurrency(invoice.discount / 100, invoice.currency) }}
            </td>
          </tr>
          <tr>
            <th
              colspan="4"
              class="px-2 py-1 text-right"
            >
              Tax
              @ {{ invoice.taxPercent }}%
            </th>
            <td
              class="px-2 text-right"
            >
              {{ formatCurrency(invoice.tax / 100, invoice.currency) }}
            </td>
          </tr>
          <tr>
            <th
              colspan="4"
              class="px-2 py-1 text-right text-2xl"
            >
              Total
            </th>
            <th
              class="px-2 text-right text-2xl"
            >
              {{ formatCurrency(invoice.total / 100, invoice.currency) }}
            </th>
          </tr>
        </tfoot>
      </table>
    </BaseCard>
  </div>
  <div
    v-else
    class="p-16"
  >
    <Loader />
  </div>
</template>

<script>
import Loader from '@components/Loader'

import subscriptionsApi from '@api/subscriptions'
import { mapGetters } from 'vuex'
import { parseISO } from 'date-fns'
import { formatCurrency } from '@utils/currency'
import { formatDate } from '@utils/formatDate'
import { companyAddress } from '@utils/companyAddress'

export default {
  components: {
    Loader
  },

  props: {
    invoiceUuid: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      formatDate,
      parseISO,
      formatCurrency,
      companyAddress,

      invoice: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId',
      address: 'organisations/address',
      vatNumber: 'organisations/vatNumber'
    })
  },

  created() {
    subscriptionsApi.invoice(this.organisationId, this.invoiceUuid)
      .then(invoice => {
        this.invoice = invoice
      })
  }
}
</script>
