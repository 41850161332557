<template>
  <div class="max-w-6xl m-auto">
    <Invoice :invoice-uuid="invoiceUuid" />
  </div>
</template>

<script>
import Invoice from '@components/Settings/Invoice'

export default {
  components: {
    Invoice
  },

  computed: {
    /**
     * @return {string}
     */
    invoiceUuid() {
      return this.$route.params.invoice
    }
  }
}
</script>
